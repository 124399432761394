@media (max-width: 768px) {
   .end_footer {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      row-gap: 10px;
      column-gap: 10px;
      align-items: center;
      margin: auto;
      margin-bottom: 5px;
   }
}

@media (min-width: 768px) {
   .end_footer {
      position: relative;
      display: inline-block;
      width: fit-content;
      justify-content: center;
      align-content: center;
      margin: auto;
      margin-bottom: 10px;
   }

   .end-buttons {
      margin-right: 20px;
   }
}

.end-card-title {
   margin: 0 0 10px;
   text-align: center;
   font-weight: bold;
   font-size: 1.4rem;
}

.end_footer__parent-buttons {
   min-height: 80px;
}

.end_points {
   /*   min-height: 30%; */
   font-size: 180px;
   text-align: center;
   margin: auto;
   margin-bottom: 10px;
   min-width: 50%;
   max-width: 500px;
   border-radius: 10px;
   border: 3px solid black;
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: auto auto auto;
   row-gap: 10px;
   column-gap: 10px;
   padding: 10px;
}
.end_points_text {
   font-size: 25px;
   color: rgb(171, 92, 124);
   font-weight: bold;
   line-height: 1.1;
   margin: 0;
}

.text-points {
   font-size: 50px;
   line-height: 0.8;
   display: block;
   margin: auto;
   margin-bottom: 0px;
   margin-top: 0px;
   color: rgb(171, 92, 124);
}

.estadisticas {
   display: grid;
   grid-template-rows: 1fr 1fr 1fr;
   grid-template-columns: 1fr 1fr;
   line-height: 1.1;
   font-size: 1.5rem;
   row-gap: 5px;
   margin: auto;
   width: 100%;
   justify-content: center;
   padding-top: 10px;
}

.end-buttons {
   max-width: 190px;
   min-width: 120px;
}

.end_share {
   justify-self: end;
}

.stats-header {
   font-weight: bold;
   color: black;
   padding-right: 10px;
   text-align: right;
}

.stats-data {
   color: #0f5ab6;
   font-weight: bold;
   text-align: left;
}

.stat-icon {
   color: black;
}

.stat-grid {
   display: grid;
   grid-template-columns: 1fr 1fr;
}

.points-block {
   margin: 5px 0px 5px;
}

.center-border {
   height: 3px;
   background-color: black;
}
