/* .leaderboard {
   min-height: 350px;
} */

.leader {
   display: grid;
   grid-template-columns: 1fr 5fr 2fr;
   margin-bottom: 2px;
   margin-top: 2px;
   line-height: 10px;
}

.leader-board__title {
   margin: 0px 0 5px;
   color: #0f5ab6;
   font-weight: bold;
   text-align: center;
}

.top-ten {
   position: relative;
}

.top-ten .share__container {
   padding-bottom: 0px !important;
}

ol {
   color: #ccc;
   list-style-type: none;
   text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

ol li {
   position: relative;
   font: bold italic 30px/1.5 Helvetica, Verdana, sans-serif;
   margin-bottom: 5px;
}

li p {
   font: 30px "Poppins", Helvetica, sans-serif;
   color: #555;
   align-self: end;
   text-align: left;
   margin-bottom: 5px;
}

.position {
   color: blue;
}

.top-total {
   color: green;
   justify-self: right;
}

/* .top_title {
   display: grid;
   grid-template-columns: 19fr 1fr;
   align-items: baseline;
} */

.shareGame {
   color: blue;
   font-size: 25px;
}

.leaders-table {
   width: 100%;
   white-space: nowrap;
   table-layout: fixed;
   /* //max-width: 500px; */
   display: table;
   max-height: fit-content;
   margin: auto;
}
.leaders-table__players {
   overflow: hidden;
   text-overflow: ellipsis;
   width: fit-content;
   padding: 0 !important;
   padding-top: 1;
   padding-bottom: 1;
}
.leaders-table__total {
   width: fit-content;
   max-width: 125px;
   padding: 0 !important;
   padding-top: 1;
   padding-bottom: 1;
}
