.main-title {
   margin-top: 10px;
}

.app-title {
   line-height: 0.8;
   margin-bottom: 0;
}


