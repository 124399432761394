@media (max-width: 768px) {
   .new-game-btn {
      padding-top: 10px !important;
      text-align: center !important;
      max-width: 100% !important;
   }

   .start__inline {
      display: inline-block;
      margin: 5px 0px 5px;
      width: 100%;
   }

   .start__bar {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      margin: 10px 0 10px;
   }

   .new-game__input {
      width: 100%;
      margin: auto;
   }

   .left-button {
      justify-self: start;
   }

   .right-button {
      justify-self: end;
   }

   .start__share-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: auto;
      align-content: left;
   /* column-gap: 5px; */
   }

   .start__share-btns, .btn-game-danger {
      margin: 0px;
      max-width: 100% !important;
   }
}

@media (min-width: 768px) {
   .start__inline {
      display: inline-block;
      margin: 10px 10px 5px;
      min-width: 250px;
   }

   .start__bar {
      display: inline-block;
      margin: 10px 0 10px;
   }

   .new-game__input {
      max-width: 300px;
      margin: auto;
   }

   .start__share-group {
      max-width: 300px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: auto;
      align-content: left;
   /* column-gap: 5px; */
   }

   .start__share-btns {
      margin: 0px;
      width: 110px;
   }

   
   

}

.new-game-btn {
   text-align: right;
}

.new-game-btn button {
   width: 80%;
}

.new-game-btn:active {
   top: 0.1em;
}

@media all and (max-width: 30em) {
   .new-game-btn {
      display: block;
      margin: 0.4em auto;
   }
}
.new-game__button {
   max-width: 170px;
   margin: 0px 5px 0px;
}




.start__top-30-button:focus {
   background-color: #0f5ab6;
   border-color: #0f5ab6;
   outline-color: #0f5ab6;
}

.start__top-30-button:active {
   background-color: rgb(110, 58, 79);
   border-color: rgb(110, 58, 79);
}





/* d-inline my-1 text-center */

.bottom { 
   display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    column-gap: 10px;
    row-gap: 5px;
    margin-top: 15px;
}
