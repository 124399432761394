.hrOr {
    height: 1px;
}

.loginOr { 
    display: grid;
    grid-template-columns: 4fr 1fr 4fr;
    align-content: center;
}
.spanOr {
    text-align: center;
    justify-self: center;
}	

.new-account { 
    text-decoration: underline;
    color: blue;
    justify-self: left;
    align-self: center;
}

.btn-new-account{
    justify-self: right;
    width: 100%;
}

.Loginbtns { 
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    align-content: center;    
}

.LoginForm { 
    display: grid;
    row-gap: 10px;
}


