html {
   background: url("./img/94 blurred.jpg") no-repeat center center fixed;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
}

body {
   background-color: transparent !important;
   font-family: "Poppins", sans-serif !important;
}

@media (min-width: 768px) {
   .container {
      margin-top: 3% !important;
      text-align: center;
   }

   .game-card {
      max-width: 96% !important;
      margin: auto;
   }

   .slogan {
      font-size: 0.4em;
      margin: 0;
   }
}

@media (max-width: 768px) {
   .flex-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: auto;
   }
   .container {
      min-width: 96% !important;
      max-width: 96% !important;
      min-width: 96% !important;
      max-width: 96% !important;
      padding-left: 0px;
      padding-right: 0px;
   }

   .hr-light {
      margin-top: 0.1em !important;
      margin-bottom: 0.1em !important;
   }

   .main-title {
      font-size: 2.5rem !important;
   }

   .slogan {
      font-size: 1.2rem;
      margin: 0;
   }
}

.game-card {
   min-height: fit-content;
   display: grid;
   grid-template-columns: 1fr;
   justify-content: left;
   align-content: start;
   /* width: 100%; */

   /*    margin: auto; */
   position: relative;
   padding: 10px 15px 10px 20px;
   box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
   border-radius: 5px;
   background: rgba(250, 250, 250, 0.8);

   /* box-shadow: inset 0 0 0 2px #0f5ab6; */
}

.game-card-body {
   flex: 1 1 auto;
   min-height: 1px;
   padding-top: 0.25rem;
}

.main-title {
   color: #fff;
   font-weight: 300;
   font-size: 4vw;
   margin: 0;
   text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.card {
   background-color: rgba(126, 123, 215, 0.2);
}

.md-form label {
   color: #ffffff;
}

h1,
h6 {
   color: #fff !important;
}

h6 {
   line-height: 1.7;
}

.vertical-center {
   min-height: 100%; /* Fallback for browsers do NOT support vh unit */
   min-height: 100vh; /* These two lines are counted as one :-)       */
   display: flex;
   align-items: center;
}

.card {
   -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
   box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
   border: 0;
   font-weight: 400;
}

.App {
   text-align: center;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
   .App-logo {
      animation: App-logo-spin infinite 20s linear;
   }
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: rgba(255, 255, 255, 1);
}

.App-link {
   color: #61dafb;
}

.btn-game-primary {
   color: rgba(255, 255, 255, 1);
   background-color: #0f5ab6;
   /* border-color: #0f5ab6; */
   border: 2px solid white;
   max-width: 190px;
}

.btn-game-primary:active {
   transform: scale(0.9);
}

.btn-game-primary:hover {
   color: rgba(255, 255, 255, 1);
}

.btn-game-danger {
   background-color: rgb(143, 87, 124);
   /* border-color: rgb(143, 87, 124); */
   border: 2px solid white;
   color: rgba(255, 255, 255, 1);
   max-width: 190px;
}

.btn-game-danger:hover {
   color: rgba(255, 255, 255, 1);
}

.btn-game-danger:active {
   transform: scale(0.9);
}

.progress-bar {
   background-color: #0f5ab6;
}

.progress {
   background-color: #81a7d5;
   font-weight: 700;
   font-size: 1.125em;
   position: relative;
   width: 100%;
}

.progress__span {
   color: rgba(255, 255, 255, 1);
   font-weight: 700;
   font-size: 18px;
   position: absolute;
   width: 100%;
}

.loading-animation {
   margin: auto;
}

.share__cont {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-template-rows: 1fr 1fr 1fr;
   column-gap: 10px;
   row-gap: 10px;
}
