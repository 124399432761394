@keyframes bounce {
   0% {
      opacity: 0;
      transform: translateY(-15px);
   }

   60% {
      opacity: 1;
      transform: translateY(5px);
   }

   80% {
      transform: translateY(-10px);
   }

   100% {
      transform: translateY(0);
   }
}

@media (max-width: 768px) {
   .game_footer {
      left: 0px;
      bottom: 0px;
      position: fixed;
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 10px;
      background-color: #81a7d5;
      background-color: #95758a;
   }
}

@media (min-width: 768px) {
   /* .game_footer {
      bottom: 10px;
      left: 0px;
      margin: auto;
      position: relative;
      height: 2.5em;
      align-items: center;
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      column-gap: 2em;
   } */
   .game_footer {
      left: 0px;
      bottom: 0px;
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: center;
      column-gap: 20px;
      align-items: center;
      padding: 10px;
   }
}

.quick_add_React {
   display: grid;
   grid-template-columns: 1fr;
   justify-content: left;
   align-content: start;
   width: 90%;
   margin: auto;
   padding: 5px 0 15px 15px;
   box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
   border-radius: 10px;
}
.game-card-title {
   margin-bottom: 0.75rem;
   text-align: justify;
}
.add_title {
   display: grid;
   grid-template-columns: 100%;
}
.add_body {
   display: grid;
   grid-template-columns: 1fr;
   padding-bottom: 2.5em;
}

.input-group-text {
   display: flex;
   align-items: center;
   padding: 0.375rem 0.75rem;
   margin-bottom: 0;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.5;
   color: #495057;
   text-align: center;
   white-space: nowrap;
   background-color: #e9ecef;
   border: 1px solid #ced4da;
   border-radius: 0.25rem;
   width: 200px;
}

#todoQuickAddContact_React {
   width: 100%;
   grid-gap: 10px;
}

#quick_add_React_InnerBox {
   display: grid;
   grid-template-rows: 35px;
   grid-gap: 7px;
}
.input-group-eng {
   display: grid;
   grid-template-columns: 200px auto;
   grid-template-rows: 28px;
   border-top-left-radius: 5px;
   border-bottom-left-radius: 5px;
   box-shadow: 0 3px 4px -5px #000;
}
.input-group-eng input[type="text"],
.input-group-eng input[type="email"] {
   text-rendering: auto;
   letter-spacing: normal;
   word-spacing: normal;
   text-transform: none;
   text-indent: 0;
   text-shadow: none;
   display: inline-block;
   text-align: start;
   -webkit-appearance: textfield;
   -webkit-rtl-ordering: logical;
   cursor: text;
   font: 400 13.3333px Arial;
   border: none;
   border-image: none;
   border-top: 1px solid #c8c8c8;
   border-right: 1px solid #c8c8c8;
   border-bottom: 1px solid #c8c8c8;
   border-top-right-radius: 5px !important;
   border-bottom-right-radius: 5px !important;
   font-size: 17px !important;
   height: 28px !important;
   width: auto !important;
}
.input-group-eng select {
   text-rendering: auto;
   letter-spacing: normal;
   word-spacing: normal;
   text-transform: none;
   text-indent: 0;
   text-shadow: none;
   display: inline-block;
   text-align: start;
   -webkit-appearance: textfield;
   -webkit-rtl-ordering: logical;
   cursor: pointer;
   font: 400 13.3333px Arial;
   border: none;
   border-image: none;
   border-top: 1px solid #c8c8c8;
   border-right: 1px solid #c8c8c8;
   border-bottom: 1px solid #c8c8c8;
   border-top-right-radius: 5px !important;
   border-bottom-right-radius: 5px !important;
   font-size: 15px !important;
   height: 28px !important;
   width: auto !important;
   color: rgba(255, 255, 255, 1);
   background-color: #1d75d2;
}
.input-group-eng-prepend {
   display: grid;
   background-color: #f5f5f5;
   border-top-left-radius: 5px;
   border-bottom-left-radius: 5px;
   border-top: 1px solid #c8c8c8;
   border-left: 1px solid #c8c8c8;
   border-bottom: 1px solid #c8c8c8;
   font-size: 14px;
   letter-spacing: 0.5px;
   font-weight: 600;
   color: #142737;
}
.input-group-eng-text {
   justify-self: start;
   align-self: center;
   padding: 2px 8px 3px 8px;

   border-top-left-radius: 6px;
   border-bottom-left-radius: 6px;
}
.MuiButton-containedPrimary {
   width: 100%;
   color: #fff;
   background-color: #1976d2 !important;
}

.MuiButton-containedPrimary:hover {
   background-color: #0e4378;
}

.answer-ok {
   font-size: 30px;
   color: rgba(255, 255, 255, 1);
   margin-top: 5px;
}

.answer-wrong {
   font-size: 30px;
   color: rgba(255, 255, 255, 1);
   margin-top: 5px;
}

.text-big {
   font-size: 1.2em !important;
   font-weight: 800 !important;
}

.game-stats {
   display: grid;
   grid-template-columns: 1fr 2fr 1fr;
   margin-bottom: 0.5em;
   border-bottom: 2px black solid;
}

.progress {
   position: relative;
   height: 100%;
}

.number {
   text-align: left;
   font-weight: 700;
}

.player {
   text-align: center;
   font-size: 1.125em;
   font-weight: 700;
   color: black;
}

.points {
   text-align: right;
   font-variant-numeric: oldstyle-nums;
   font-weight: 700;
   font-size: 1.125em;
   /* color: #0f5ab6; */
   color: black;
}

.pregunta {
   font-family: "Roboto", sans-serif;
   padding-bottom: 5px;
   border-bottom: 2px solid black;
   color: #0f5ab6;
   font-weight: bold;
}

.respuesta {
   font-family: "Poppins", sans-serif;
   font-weight: normal;
}

.bounce {
   animation-name: bounce;
   animation-fill-mode: both;
   animation-duration: 0.7s;
}

.cita-link {
   color: #0f5ab6;
   text-decoration: underline;
}

.btn-outline-light,
.btn-outline-light:active {
   color: black !important;
   border-color: black !important;
   background-color: transparent !important;
}

.btn-outline-light--selected,
.btn-outline-light--selected:active {
   color: rgba(255, 255, 255, 1) !important;
   border-color: #0f5ab6 !important;
   background-color: #0f5ab6 !important;
   box-shadow: none !important;
   outline: 0px !important;
}

.btn-danger-sel,
.btn-danger-sel:active {
   background-color: #dc3545 !important;
   color: rgba(255, 255, 255, 1) !important;
   opacity: 1 !important;
   box-shadow: none !important;
   outline: 0px !important;
}

.btn-success-sel,
.btn-success-sel:active,
.btn-success,
.btn-success:active,
.btn-success:hover {
   background-color: #28a745 !important;
   border: 0px !important;
   color: rgba(255, 255, 255, 1) !important;
   opacity: 1 !important;
   box-shadow: none !important;
   outline: 0px !important;
}

[class*="badge-b"] {
   font-size: 1em;
   height: 1.7em;
   width: 1.7em;
   text-align: center;
   margin-right: 10px;
   align-self: center;
   position: absolute !important;
   left: -28px;
   top: 0 !important;
   bottom: 0 !important;
   margin: auto !important;
   border-radius: 50%;
   display: flex !important;
   align-items: center !important;
   justify-content: center !important;
}

[class*="badge-b-outline-light"] {
   background-color: rgba(255, 255, 255, 1);
}

.badge-b-outline-light {
   color: black;
   border: 2px solid black;
   font-weight: bolder;
}

.badge-b-outline-light--selected {
   text-decoration: underline;
   border: 2px solid rgba(255, 255, 255, 1);
   background-color: black;
   color: rgba(255, 255, 255, 1);
}

.badge-b-danger,
.badge-b-danger-sel {
   font-size: 1em;
   height: 1.7em;
   width: 1.7em;
   text-align: center;
   margin: 0 10px 0 0;
}

.badge-b-danger {
   border: 2px solid black;
}

.badge-b-danger-sel {
   border: 2px solid rgba(255, 255, 255, 1);
   text-decoration: underline;
   background-color: black;
   color: rgba(255, 255, 255, 1);
}

.badge-b-success,
.badge-b-success-sel {
   font-size: 1em;
   height: 1.7em;
   width: 1.7em;
   text-align: center;
   margin: 0 10px 0 0;
}

.badge-b-success {
   background-color: rgba(255, 255, 255, 1);
   color: black;
   border: 2px solid black;
}

.badge-b-success-sel {
   text-decoration: underline;
   background-color: black;
   border: 2px solid rgba(255, 255, 255, 1);
   color: rgba(255, 255, 255, 1);
}

.badge-answer-text {
   font-size: 1em;
   text-align: left;
   align-self: center;
}

.answer-grid {
   display: grid;
   grid-template-columns: 1fr auto;
   position: relative;
   padding-left: 10px;
}

/* .terminar {
   justify-self: end;
   width: 100%;
} */

.answer-icon {
   align-self: center;
   margin-left: 10px;
}
