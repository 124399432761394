#firebaseui_container {
    background: rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    /*min-height: 150px;*/
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    box-shadow: none;
    max-width: 100% !important;
}

.firebaseui-idp-list { 
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  display: grid;
  row-gap: 10px;
  column-gap: 5px;
}

.firebaseui-idp-list>.firebaseui-list-item { 
  margin-bottom: 0px !important;
}

