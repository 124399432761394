.PlayerForm { 
    display: grid;
    row-gap: 10px;
}

.Actions { 
    display: grid;
    grid-template-columns: auto auto auto;
    align-content: center;
    grid-column-gap: 5px;
}

.CancelButton { 
    justify-self: center;
}

.LogoutButton { 
    justify-self: right;
}
